.NotificationToast {
  top: 1.5em;
  border-radius: 5px;

  &__width-unset {
    background-color: transparent;
  }
}

.Toastify__toast-container:has(div.NotificationToast__width-unset) {
    width: unset;

    .Toastify__close-button {
      z-index: 1;
    }
}